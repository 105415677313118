import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import { Circle as CircleIcon } from '@mui/icons-material';
import { EMOJI_MAP, FORMAT_DATE, URL_REGEX } from './constants';
import { emojiIndex } from 'emoji-mart';
import { ConvertViToEn } from './convert-vi-to-en';

const MENTION_REGEX = /\@\<.+?\>\[.+?\]/gi;

export default class Utils {
  static isEmptyString = (str) => {
    const string = str !== undefined || str !== null ? String(str) : '';
    return !str || string.length === 0 || !string.trim();
  };

  static isEmptyArray = (array) => {
    return array === undefined || array === null || array.length === 0;
  };

  static isFunction = (func) => {
    return func && typeof func === 'function';
  };

  static isEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  static isResponseSuccess = (response) => {
    return response && response.status === 200 && response.data && response.data.code === 'ok';
  };

  static removeEmptyAttributes = (data) => {
    let obj = data;
    if (obj) {
      obj = { ...data };
      Object.keys(obj).forEach((key) => {
        if (obj[key] === undefined || obj[key] === null) {
          delete obj[key];
        }
      });
    }
    return obj;
  };

  static convertTimeToTimestamp = (time) => {
    return Date.parse(time);
  };

  static isNumber = (num) => {
    const regex = /^\d+$/;
    return regex.test(num);
  };

  static parseTime = (dateTime) => {
    return moment(dateTime).format('h:mm A');
  };

  static parseFullDateTime = (dateTime) => {
    return moment(dateTime).format('DD MMM [at] h:mm A');
  };

  static checkDateIsToday = (dateTime) => {
    return this.isSameDay(dateTime, new Date());
  };

  static checkDateIsYesterday = (dateTime) => {
    return moment().subtract(1, 'd').isSame(moment(dateTime), 'd');
  };

  static checkDateIsTomorrow = (dateTime) => {
    return moment(dateTime).subtract(1, 'd').isSame(moment(), 'd');
  };

  static isSameDay = (firstDate, secondDate) => {
    return moment(firstDate).isSame(moment(secondDate), 'd');
  };

  static isSameTime = (firstDate = new Date(), secondDate = new Date()) => {
    return moment(firstDate).isSame(moment(secondDate));
  };

  static calculateProgressTime = (from, to) => {
    const fromTime = moment(from);
    const toTime = moment(to);
    const today = moment();
    const diff = toTime.diff(fromTime, 'days');
    const diffToday = toTime.diff(today, 'days');

    return {
      range: diff,
      remain: diffToday,
    };
  };

  static formatNumber = (num) => {
    // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return numeral(num).format('0,0');
  };

  static isNotNullAndUndefined = (value) => {
    return !_.isNull(value) && !_.isUndefined(value);
  };

  static generateMonthsInYear = (monthFormat = 'MM-yyyy') => {
    const months = [];
    const dateStart = moment().set('month', 0);
    const dateEnd = moment(dateStart).add(11, 'month');
    while (dateEnd.diff(dateStart, 'months') >= 0) {
      months.push(dateStart.format(monthFormat));
      dateStart.add(1, 'month');
    }
    return months;
  };

  static getShortenNumberString = (number) => {
    if (!number || !_.isNumber(number) || number < 1000) {
      return number;
    } else if (number < 1000000) {
      return `${~~(number / 1000)}K`;
    } else {
      return `${~~(number / 1000000)}M`;
    }
  };

  static disabledDate = (current, max = null, min = null) => {
    if (!current) {
      return false;
    }
    if (max) {
      return current >= max.endOf('day');
    }
    if (min) {
      return min >= current.endOf('day');
    }
  };

  static range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  static disabledTime = (current) => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  static async validateMinDate(validator, date, name, min = null, hasTime = false) {
    let promise = new Promise(function (resolve, reject) {
      if (min && date && date.isBefore(min)) {
        reject(
          new Error(
            `Vui lòng nhập ngày sau ${name}: ${min.format(
              hasTime ? FORMAT_DATE.DATE_TIME_FORMAT : FORMAT_DATE.DATE_FORMAT,
            )}`,
          ),
        );
      }
      resolve('done');
    });
    return promise;
  }

  static validateMaxDate(validator, date, name, max = null, hasTime = false) {
    let promise = new Promise(function (resolve, reject) {
      if (max && date && date.isAfter(max)) {
        reject(
          new Error(
            `Vui lòng nhập ngày trước ${name}: ${max.format(
              hasTime ? FORMAT_DATE.DATE_TIME_FORMAT : FORMAT_DATE.DATE_FORMAT,
            )}`,
          ),
        );
      }
      resolve('done');
    });
    return promise;
  }

  static validateDatePicker(fieldMinName, fieldMaxName, min = null, max = null, hasTime = false) {
    let validators = [];
    if (min) {
      validators.push({
        validator: async (validator, value) =>
          this.validateMinDate(validator, value, fieldMinName, min, hasTime),
      });
    }
    if (max) {
      validators.push({
        validator: async (validator, value) =>
          this.validateMaxDate(validator, value, fieldMaxName, max, hasTime),
      });
    }
    return validators;
  }

  static convertJsonToFormData = (data) => {
    var formData = new FormData();
    for (var key in data) {
      if (Array.isArray(data[key]) && key == 'to') {
        data[key].forEach((file) => {
          formData.append('to', file);
        });
        continue;
      }
      if (Array.isArray(data[key]) && key == 'bcc') {
        data[key].forEach((file) => {
          formData.append('bcc', file);
        });
        continue;
      }
      if (Array.isArray(data[key]) && key == 'cc') {
        data[key].forEach((file) => {
          formData.append('cc', file);
        });
        continue;
      }
      if (Array.isArray(data[key]) && key == 'file') {
        data[key].forEach((file) => {
          formData.append('file', file);
        });
        continue;
      }
      // if (Array.isArray(data[key]) && key !== 'to' && key !== 'bcc' && key !== 'cc') {
      //   for (let i = 0; i < data[key].length; i++) {
      //     for (var keyInArray in data[key][i]) {
      //       formData.append(`${key}[${i}].${keyInArray}`, data[key][i][keyInArray]);
      //     }
      //   }
      //   continue;
      // }
      if (Array.isArray(data[key])) {
        for (let i = 0; i < data[key].length; i++) {
          for (var keyInArray in data[key][i]) {
            formData.append(`${key}[${i}].${keyInArray}`, data[key][i][keyInArray]);
          }
        }
        continue;
      }

      data[key] && formData.append(key, data[key]);
    }
    return formData;
  };

  static convertJsonToFormDataFn = (data) => {
    var formData = new FormData();
    for (var key in data) {
      if (Array.isArray(data[key]) && key === 'conts') {
        for (let i = 0; i < data[key].length; i++) {
          for (var keyInArray in data[key][i]) {
            formData.append(`${key}[${i}].${keyInArray}`, data[key][i][keyInArray]);
          }
        }
        continue;
      }
      if (Array.isArray(data[key]) && key === 'orderFiles') {
        data[key].forEach((file) => {
          formData.append('Files', file);
        });
        continue;
      }

      formData.append(key, data[key]);
    }
    return formData;
  };

  static checkIsDate = (date) => {
    if (!date) return false;
    if (
      date.includes('1970-01-01T') ||
      date.includes('0001-01-01T') ||
      date.includes('1900-01-01T')
    )
      return false;
    const year = moment(date).year();
    if (year < 1900) {
      return false;
    }
    return true;
  };

  static formatDateFn = (date, select = null) => {
    if (!date) return '';
    if (
      date.includes('1970-01-01T') ||
      date.includes('0001-01-01T') ||
      date.includes('1900-01-01T')
    )
      return '';
    const year = moment(date).year();
    if (year < 1900) {
      return '';
    }
    return (
      <span>
        {moment(date).format('DD/MM/YYYY') === 'Invalid date'
          ? ''
          : moment(date).format(select ? `${select}` : 'DD/MM/YYYY')}
      </span>
    );
  };

  static formatSimpleDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  static formatDateHourFn = (date) => {
    if (!date) return '';
    if (
      date.includes('1970-01-01T') ||
      date.includes('0001-01-01T') ||
      date.includes('1900-01-01T')
    )
      return '';
    const year = moment(date).year();
    if (year < 1900) {
      return '';
    }
    return (
      <span>
        {moment(date).format('DD/MM/YYYY') === 'Invalid date'
          ? ''
          : moment(date).format('DD/MM/YYYY HH:mm')}
      </span>
    );
  };

  static convertToDate = (value) => {
    if (value) {
      const year = moment(value).year();
      if (year < 1900) {
        return null;
      } else if (value === '0001-01-01T00:00:00+07:00' || value === '1900-01-01T00:00:00+07:00') {
        return null;
      } else {
        return moment(value);
      }
    } else {
      return null;
    }
  };

  static convertToDateFormat = (value) => {
    if (value) {
      const year = moment(value).year();
      if (year < 1900) {
        return null;
      } else if (value === '0001-01-01T00:00:00+07:00' || value === '1900-01-01T00:00:00+07:00') {
        return null;
      } else {
        return moment(value).format('YYYY-MM-DD');
      }
    } else {
      return null;
    }
  };

  static showArrayInTale = (arrayData, marginLeft = '10px') => {
    if (!arrayData || arrayData?.length === 0) return '';

    if (arrayData.length === 1) return <div style={{ marginLeft }}>{arrayData[0]}</div>;

    return arrayData.map((item, index) => (
      <div key={index} style={{ marginLeft, marginBottom: '5px' }}>
        <CircleIcon color="primary" sx={{ fontSize: 6, marginRight: '5px' }} />
        {item}
      </div>
    ));
  };

  static displayArrayToString = (arrData, keyValue = '') => {
    if (arrData.length === 0) return;
    return arrData.map((item, index) => (
      <span key={index}>
        {keyValue ? item[keyValue] : item}
        {arrData.length > 1 && arrData.length > index + 1 ? ', ' : ''}
      </span>
    ));
  };

  static stringToHTML = function (str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  static htmlToString = function (html) {
    return html.replace(/<[^>]+>/g, '');
  };
  static isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res;
  }
  static parseUsersMentionFromText(text, users = []) {
    if (Array.isArray(text)) {
      const results = [];

      text.forEach((txt) => {
        if (typeof txt === 'string') {
          const mentions = txt.split(MENTION_REGEX);
          mentions.forEach((mention, index) => {
            if (mention && mention.match(MENTION_REGEX)) {
              const userName = mention.replace(/\@<.+?\>\[/g, '').replace(']', '');
              results.push(
                <span className="mention" key={txt + mention + index}>
                  @{userName}
                </span>,
              );
            } else {
              results.push(mention);
            }
          });
        } else {
          results.push(txt);
        }
      });
      return results;
    }
    return `${text}`.replace(MENTION_REGEX, (m) => {
      // const uid = m.replace(/@</g, '').replace(/>\[.+?\]/gi, '');
      const userName = m.replace(/\@<.+?\>\[/g, '').replace(']', '');
      return <span style={{ cursor: 'pointer' }}>@{userName}</span>;
    });
  }
  static parseHyperLinkFromText(text) {
    var urlRegexM =
      /(\b(http(s)?|ftp|file):\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    const plainText = `${text}`.split(URL_REGEX);
    const res = [];
    plainText.map((txt, index) => {
      if (txt && txt.trim() && txt.match(urlRegexM)) {
        res.push(
          <a
            key={text + index + Math.random()}
            href={txt?.startsWith('http') ? txt : `http://${txt}`}
            target="_blank"
            style={{
              textDecoration: 'none',
              fontStyle: 'italic',
            }}
          >
            {txt}
          </a>,
        );
      } else if (`${txt}`.match(MENTION_REGEX)) {
        res.push(
          <a
            key={text + index + Math.random()}
            href={'#' + txt.replace(/@</g, '').replace(/>\[.+?\]/gi, '')}
            style={{
              textDecoration: 'none',
              fontStyle: 'italic',
            }}
          >
            @{txt.replace(/\@<.+?\>\[/g, '').replace(']', '')}
          </a>,
        );
      } else {
        res.push(txt);
      }
    });
    return res;
  }

  static getEmoji(emojiText) {
    let emoji;
    switch (emojiText) {
      case '*':
        emoji = emojiIndex.search(':*')[0].native;
        break;
      case 'b':
        emoji = emojiIndex.search(':b')[0].native;
        break;
      case 's':
        emoji = emojiIndex.search(':s')[0].native;
        break;
      case 'D':
        emoji = emojiIndex.search(':)')[1].native;
        break;
      case ')':
        emoji = emojiIndex.search(':)')[0].native;
        break;
      case '(':
        emoji = emojiIndex.search(':(')[0].native;
        break;
      case 'P':
        emoji = emojiIndex.search(':P')[0].native;
        break;
      case 'o':
        emoji = emojiIndex.search('Hushed')[0].native;
        break;
      case 'O':
        emoji = emojiIndex.search('Hushed')[0].native;
        break;
      default:
        emoji = '';
    }
    return emoji;
  }

  static renderEmoji(text) {
    let regex = /(?::\)|:\(|:\^\(|:\^D|<3|:D|:b|:s|:v|:\||:p|:\*|>:@|;\)|;\(|:O'|;\)|8\)|>:@)/g;
    return text.replace(regex, (m) => EMOJI_MAP[m] || m);
  }

  static onExecOutside(ref, exec) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        exec && exec();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }
  static showTimeMessage = (time) => {
    const seconds = parseInt((new Date().getTime() - time) / 1000);
    if (seconds < 60) {
      return 'vừa mới gửi';
    }
    const minutes = parseInt(seconds / 60);
    if (minutes < 60) {
      return `${minutes} ${minutes > 1 ? 'phút trước' : 'phút trước'}`;
    }
    const hours = parseInt(minutes / 60);
    if (hours < 24) {
      return `${hours} ${hours > 1 ? 'giờ trước' : 'giờ trước'}`;
    }
    // const days = parseInt(hours / 24);
    // if (days < 30) {
    //   return `${days} ${days > 1 ? 'ngày trước' : 'ngày trước'}`;
    // }
    return moment(time).format('DD-MM HH:mm');
  };

  static generatePlaceholderImage = (text = '', size = 48, bg = '808080') => {
    return `https://plchldr.co/i/${size}x${size}?bg=${bg}&text=` + text;
  };

  static exportTemplate = (data, outputFilename) => {
    if (!data || !outputFilename) return;
    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
    window.UIMessage?.success('Export thành công');
    link.remove();
  };

  static convertValSearch = (string) => {
    if (!string) return '';
    return ConvertViToEn(string).trim().toUpperCase();
  };
}
